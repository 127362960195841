<template>
    <div id="productDetails">
        <div class="navbar">
            <button @click="returnPage"><i class="iconfont icon-fanhui1"></i>商品详情</button>
        </div>

        <div class="content">
            <div class="cover">
                <img v-lazy="productDetail.coverImg"/>
            </div>

            <van-skeleton 
            :row="5"  
            :loading="detailsLoading" 
            :animate="true" 
            class="info infoLoading"/>

            <div class="info" v-if="!detailsLoading">
                <div class="price">
                    <!-- <h4 v-if="sku.list.length>0">￥<span>{{fixedPrice(productDetail.skus[0].salePrice)}}</span>+<span>{{productDetail.skus[0].saleIntegral}}</span>良豆</h4> -->
                    <h4 v-if="sku.list.length>0" v-html="defaultPriceShow"></h4>
                    <h4 v-if="productDetail.specification.length < 1">￥<span>{{fixedPrice(productDetail.skus[0].salePrice)}}</span>+<span>{{productDetail.skus[0].saleIntegral}}</span>良豆</h4>
                </div>
                <div class="title">
                    <h4><span>限时热卖中</span>{{productDetail.productName}}</h4>
                </div>
                <div class="tips">
                    <p>{{productDetail.saleDescription}}</p>
                    <!-- <p>湖北武汉 | 运费10元</p> -->
                </div>

                <div class="selectSku" v-if="productDetail.specification.length > 0">
                    <h4>选择规格</h4>
                    <button @click="skuShow = true">
                        <span>{{selectSku ? selectSku.name : `选择${defaultName()}`}}</span>
                        <i class="iconfont icon-xiayibu1"></i>
                    </button>
                </div>
            </div>

            <div class="details">
                <h4>商品描述</h4>
                <van-skeleton 
                :row="4" 
                row-width="99%" 
                :loading="detailsLoading" 
                :animate="true" 
                class="detailsLoading"/>
                <div class="description" v-html="productDetail.productDescription"></div>
            </div>
        </div>

        <div class="detailNavBar">
            <button @click="addCard">加入购物车</button>
            <button @click="buyClicked">立即购买</button>
        </div>

        <van-sku
        v-model="skuShow"
        :sku="sku"
        :goods="goods"
        :goods-id="goodsId"
        @sku-selected="skuSelected"
        @sku-prop-selected="skuPropSelected"
        @stepper-change="stepperChange">

             <!-- 自定义 sku-header-price -->
            <template #sku-header-price="props">
                <div class="van-sku__goods-price">
                    <span class="van-sku__price-symbol">￥</span>
                    <span class="van-sku__price-num">{{ props.price }}</span>
                </div>
            </template>

            <!-- 自定义 sku actions -->
            <template #sku-actions="props">
                <div class="van-sku-actions">
                    <button @click="addCard">加入购物车</button>
                    <!-- 直接触发 sku 内部事件，通过内部事件执行 onBuyClicked 回调 -->
                    <button @click="buyClicked">立即购买</button>
                </div>
            </template>
        
        </van-sku>

        <confirmOrder 
        v-if=""
        :confirmOrderShow="confirmOrderShow" 
        :selectSku="selectSku" 
        :quantity="addCardData.quantity"
        :changeQuantity.sync="addCardData.quantity"
        :productDetail="productDetail"
        :popupShow.sync="confirmOrderShow"/>

        <Login :isLogin="isLogin" :popupShow.sync="isLogin"/>
    </div>
</template>

<script>
import {getproductdetail, addShoppingCard} from '../../api/product'
import confirmOrder from '../component/confirmOrder'
import {getauthinfo} from '../../api/user'
import Login from '../component/login'
import {getInfo} from '../../api/wxjsapi'
export default {
    data () {
        return {
            appKey: '',
            skuShow: false,
            sku: {
                // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
                // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
                tree: [
                   /*  {
                        k: '颜色', // skuKeyName：规格类目名称
                        k_s: 's1', // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
                        v: [
                            {
                            id: '1', // skuValueId：规格值 id
                            name: '红色', // skuValueName：规格值名称
                            imgUrl: 'https://img.yzcdn.cn/1.jpg', // 规格类目图片，只有第一个规格类目可以定义图片
                            previewImgUrl: 'https://img.yzcdn.cn/1p.jpg', // 用于预览显示的规格类目图片
                            },
                            {
                            id: '2',
                            name: '蓝色',
                            imgUrl: 'https://img.yzcdn.cn/2.jpg',
                            previewImgUrl: 'https://img.yzcdn.cn/2p.jpg',
                            }
                        ],
                        largeImageMode: false, //  是否展示大图模式
                    },
                    {
                        k: '尺寸', // skuKeyName：规格类目名称
                        k_s: 's2', // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
                        v: [
                            {
                            id: '3', // skuValueId：规格值 id
                            name: 's', // skuValueName：规格值名称
                            },
                            {
                            id: '4',
                            name: 'm',
                            }
                        ],
                        largeImageMode: false, //  是否展示大图模式
                    }, */
                ],
                // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
                list: [
                   
                    /* {
                        id: 2261, // skuId
                        s1: '1', // 规格类目 k_s 为 s1 的对应规格值 id
                        s2: '4',
                        price: 100, // 价格（单位分）
                        stock_num: 110 // 当前 sku 组合对应的库存
                    },
                    {
                        id: 2262, // skuId
                        s1: '2', // 规格类目 k_s 为 s1 的对应规格值 id
                        s2: '3',
                        price: 100, // 价格（单位分）
                        stock_num: 10 // 当前 sku 组合对应的库存
                    },
                    {
                        id: 2263, // skuId
                        s1: '2', // 规格类目 k_s 为 s1 的对应规格值 id
                        s2: '4',
                        price: 100, // 价格（单位分）
                        stock_num: 10 // 当前 sku 组合对应的库存
                    },
 */
                ],
                price: '0.00', // 默认价格（单位元）
                stock_num: 0, // 商品总库存
                collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
                none_sku: false, // 是否无规格商品
                hide_stock: false // 是否隐藏剩余库存
            },
            goods: {
                // 默认商品 sku 缩略图
                picture: 'https://img.yzcdn.cn/1.jpg'
            },
            goodsId: 10,
            productDetail: '', //产品详情
            selectSku: '', //当前选中sku
            addCardData: { //添加购物车入参
                shopId: '',
                productId: '', 
                sku: '',
                quantity: 1
            },
            confirmOrderShow: false,
            addressPopup: true,
            isLogin: false,
            detailsLoading: true
        }
    },
    mounted () {
        let us = window.navigator.userAgent;
        const {appKey} = this.$route.query;
        appKey && (this.appKey = appKey);
        this.getDetail();
        if (us.indexOf('MicroMessenger') > -1) {
            const {entrance, code} = this.$route.query;
            if (entrance == 1 && !localStorage.getItem('openid')) {
                if (!code) {
                    let local = encodeURIComponent(window.location.href);
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0812ff193b71ad32&redirect_uri=${local}&response_type=code&scope=snsapi_base&state=1&#wechat_redirect`
                } else {
                    this.getOpenId(code);
                }
            }

        }
    },
    computed: {
        defaultName () {
            return () => {
                const {tree} = this.sku;
                var txt = '';
                tree.map(data => {
                    txt += ` ${data.k}`;
                });
                return txt;
            }
        },
        fixedPrice () {
            return (price) => {
                return price.toFixed(2);
            }
        },
        defaultPriceShow () {
            if (this.selectSku) {
                const {price, saleIntegral} = this.selectSku;
                return `￥<span>${(price/100).toFixed(2)}</span> + <span>${saleIntegral}</span>良豆`
            } else {
                const {skus} = this.productDetail;
                return `￥<span>${skus[0].salePrice.toFixed(2)}</span> + <span>${skus[0].saleIntegral}</span>良豆`
            }
        }
    },
    components: {confirmOrder, Login},
    methods: {

         async getOpenId (code) {
            let res = await getInfo({code});
            if (res.code === 1) {
                localStorage.setItem('openid', res.data);
            }
        },

        /* 详情 */
        async getDetail () {
            const {appKey} = this;
            const {productId} = this.$route.query;
            let res = await getproductdetail({appKey, productId});
            const {code, data, msg} = res;
            if (code === 1) {
                this.productDetail = data;
                this.addCardData.productId = data.productId;
                const {skus, specification} = this.productDetail;
                // console.log(skus);
                if (specification.length > 0) {
                    this.treeData(specification);
                    this.listData(skus);
                } else {
                    // this.listData(skus);

                    this.sku.collection_id = skus[0].sku;
                    this.sku.stock_num = skus[0].stock;
                    this.sku.price = skus[0].salePrice;
                    this.sku.none_sku = true;
                }
                var stock = 0;
                skus.map(res => {
                    stock += res.stock;
                })
                this.sku.stock_num = stock;
                this.goods.picture = this.productDetail.coverImg;
                this.detailsLoading = false;
                console.log(this.sku);

            } else {
                this.$toast(msg);
            }
        },

        /* 根据数据生成对应的tree结构 */
        treeData (tree) {
            let arr = [];
            for (var i=0; i<tree.length; i++) {
                let arr1 = {};
                arr1.k = tree[i].name;
                arr1.k_s = `s${i}`;
                arr1.v = [];
                for (var n=0; n<tree[i].attributes.length; n++) {
                    var arr2 = {};
                    arr2.id =  tree[i].attributes[n].id;
                    arr2.name =  tree[i].attributes[n].attributes;
                    arr2[`s${i}`] = tree[i].attributes[n].id;
                    arr2.imgUrl = tree[i].attributes[n].img;
                    arr1.v.push(arr2);
                }
                arr.push(arr1);
            }
            this.sku.tree = arr;
        },

        /* 根据数据生成对应的list结构 */
        listData (sku) {
            console.log(sku)
            var arr = [];
            for (var i=0; i<this.sku.tree.length; i++) {
                arr.push(this.sku.tree[i].v);
            }

            //获取到对应的笛卡尔积
            console.log(arr);
            let data = this.calcDescartes(arr); 
            console.log(data);
            /* data.map(data => {
                data.reverse();
            });
            console.log(data); */
            //整合笛卡尔积
            data = this.integrationDescartes(data);
            //根据sku添加对应的价格
            data = this.arrSkuPrice(data, sku);
            this.sku.list = data;

        },

        /* 笛卡尔积计算 */
        calcDescartes (array) {
            // console.log(array)
            if (array.length < 2) return array;
            console.log('111')
            // if (array.length < 2) return array[0] || [];
            return array.reduce((total, currentValue) => {
                let res = [];
            
                total.forEach(t => {
                    currentValue.forEach(cv => {
                        if (t instanceof Array){     // 或者使用 Array.isArray(t)
                        // console.log(t);
                        // console.log(cv);
                        res.push([cv, t[1], t[0]]);

                    } else {
                        res.push([t, cv]);
                    }
                })
                })
                return res;
            })
        },

        /* 整合笛卡尔积数据 */
        integrationDescartes (data) {
            console.log(data);
            // if (data.length == 1) return data[0];
            if (data.length > 1) {
                let arr2 = [];
                for (var i=0; i<data.length; i++) {
                    // console.log(data[i]);
                    var arr3 = {};
                    for (var n=0; n<data[i].length; n++) {
                        !arr3.id && (arr3.id = '');
                        !arr3.name && (arr3.name = '');
                        if (n === data[i].length-1) {
                            arr3.id += data[i][n].id;
                        } else {
                            arr3.id += `${data[i][n].id}_`;
                        }
                        arr3.name += `${data[i][n].name} `;
                        data[i][n].s0 && (arr3.s0 = data[i][n].s0);    
                        data[i][n].s1 && (arr3.s1 = data[i][n].s1);    
                        data[i][n].s2 && (arr3.s2 = data[i][n].s2);      
                    }
                        arr3.id = `${arr3.s0 ? arr3.s0 : 0}_${arr3.s1 ? arr3.s1 : 0}_${arr3.s2 ? arr3.s2 : 0}`
                    arr2.push(arr3);
                }
                console.log(arr2);
                return arr2;
            } else {
                let arr1 = data[0];
                let arr2 = [];
                for (var i=0; i<arr1.length; i++) {
                    console.log(arr1[i]);
                    var arr3 = {};
                    arr3.id = `${arr1[i].id}_0_0`;
                    arr3.s0 = arr1[i].s0;
                    arr3.name = arr1[i].name;
                    arr2.push(arr3);
                }
                console.log(arr2);
                return arr2;
            }
        },

        /* 添加对应的sku价格 */
        arrSkuPrice (data, sku) {
            console.log(data);
            console.log(sku);
            for (var i=0; i<sku.length; i++) {
                for (var n=0; n<data.length; n++) {
                    if (sku[i].sku === data[n].id) {
                        data[n].price = sku[i].salePrice * 100;
                        data[n].saleIntegral = sku[i].saleIntegral;
                        data[n].stock_num = sku[i].stock;
                    }
                }
            }
            console.log(data);
            return data;
        },

        /* 切换sku规格类目时 */
        skuSelected (skuValue) {
            console.log(skuValue);

            var {selectedSkuComb, skuValue} = skuValue;
            this.selectSku = selectedSkuComb;
            skuValue.imgUrl && (this.selectSku.imgUrl = skuValue.imgUrl);
            const {selectSku} = this;
            console.log(selectSku);
            selectSku ? this.addCardData.sku = selectSku.id : this.addCardData.sku = '';
            
        },

        /* 切换商品属性时 */
        skuPropSelected (propValue, selectedProp, selectedSkuComb) {
            console.log(propValue, selectedProp, selectedSkuComb);
        },

        /* 购买数量发生变化时 */
        stepperChange (number) {
            console.log(number);
            this.addCardData.quantity = number;
        },

        /* 添加购物车 */
        async addCard () {
            var res = await getauthinfo();
            console.log(res);
            const {code} = res;
            if (code === 0) {
                this.$toast('请先认证手机号');
                this.isLogin = true;
            } else if (code === 1) {
                const {addCardData, sku} = this;    
                if (!sku.list.length > 0) {
                    this.addCardData.sku = sku.collection_id;
                }  
                console.log(addCardData);
                if (addCardData.sku) {
                    this.addCardData.shopId = this.productDetail.shopId;
                    var res = await addShoppingCard(addCardData);
                    console.log(res);
                    const {code, data, msg} = res;
                    if (code === 1) {
                        this.$toast('添加成功');
                    }
                } else {
                    if (this.skuShow) this.$toast('请先选择规格');
                    else this.skuShow = true;
                }
            }

        },

        /* 点击购买 */
        async buyClicked (skuData) {
            let res = await getauthinfo();
            console.log(res);
            const {code} = res;
            if (code === 0) {
                this.$toast('请先认证手机号');
                this.isLogin = true;
            } else if (code === 1) {
                 const {selectSku, addCardData, sku} = this;
                 if (sku.list.length > 0) {
                     if (selectSku) {
                         console.log(selectSku);
                         console.log(addCardData.quantity);
                         this.confirmOrderShow = true;
                     } else {
                        if (this.skuShow) this.$toast('请先选择规格');
                        else this.skuShow = true;
                     }
                 } else {
                     if (this.skuShow) {
                         this.confirmOrderShow = true;
                     } else {
                         this.skuShow = true;
                     }
                 }
            }
        },

        /* 返回上一步页面 */
        returnPage () {
            if (window.history.length > 1) {
                this.$router.go(-1);
            } else {
                const {appKey, credits, timestamp, sign, uid, code, state, integral} = this.$route.query;
                this.$router.replace({name: 'home', query:{appKey, credits, timestamp, sign, uid, code, state, integral}});
            }
        },
        
    }
}
</script>

<style lang="less" src="./index.less">

</style>